import { Box, Grid, Stack, Typography, styled } from '@mui/material';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import { useEffect, useState } from 'react';

const Banner = () => {
  const [isScreenSmall, setIsScreenSmall] = useState(false);

  useEffect(() => {
    if (window.innerWidth < 689) {
      setIsScreenSmall(true);
    } else {
      setIsScreenSmall(false);
    }
  }, []);

  window.addEventListener('resize', () => {
    if (window.innerWidth < 689) {
      setIsScreenSmall(true);
    } else {
      setIsScreenSmall(false);
    }
  });

  return (
    <Container spacing={3} justifyContent='center' alignItems='center'>
      {isScreenSmall ? (
        <>
          <Typography variant='h3' sx={{ fontWeight: 700 }}>
            VODA
            <br />
            PLYN
            <br />
            KÚRENIE
          </Typography>
        </>
      ) : (
        <Typography variant='h3' sx={{ fontWeight: 700 }}>
          VODA - PLYN - KÚRENIE
        </Typography>
      )}
      <Typography variant='subtitle1' sx={{ fontWeight: 300 }}>
        Využite naše skúsenosti a objednajte si naše služby ešte dnes.
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} style={{ paddingLeft: 0 }}>
          <Box display='flex' justifyContent='center'>
            <Contact
              spacing={2}
              alignItems='center'
              justifyContent='flex-start'
            >
              <ContactPhoneIcon className='icon' />
              <Stack spacing={1}>
                <Typography variant='h5'>Informácie | Objednávky</Typography>
                <Typography variant='h5' sx={{ fontWeight: 700 }}>
                  0904 125 770
                </Typography>
              </Stack>
            </Contact>
          </Box>
        </Grid>
        <Grid item xs={12} md={6} style={{ paddingLeft: 0 }}>
          <Box display='flex' justifyContent='center'>
            <Contact
              spacing={2}
              alignItems='center'
              justifyContent='flex-start'
            >
              <WatchLaterIcon className='icon' />
              <Stack spacing={1}>
                <Typography variant='h5'>Prevádzková doba</Typography>
                <Typography variant='subtitle1'>
                  Pondelok-Piatok: 8:00-18:00
                </Typography>
              </Stack>
            </Contact>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Banner;

const Container = styled(Stack)(({ theme }) => ({
  padding: '32px',
  backgroundColor: theme.palette.primary.main,
  textAlign: 'center',
  color: 'white',
  zIndex: 1,
  boxShadow: '0px 1px 7px gray',
  borderRadius: '10px',

  '@media (max-width: 600px)': {
    width: '100%',
    height: '100%',
  },
}));

const Contact = styled(Stack)(({ theme }) => ({
  width: '350px',
  height: '225px',
  padding: '20px',
  backgroundColor: theme.palette.secondary.main,
  boxShadow: '0px 1px 3px black',
  borderRadius: '10px',

  '& .icon': {
    fontSize: '64px',
    fill: 'white',
  },
}));
