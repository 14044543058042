import { useState } from 'react';
import {
  AppBar,
  Box,
  Button,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Toolbar,
  Typography,
  styled,
} from '@mui/material';
import { Menu as MenuIcon } from '@mui/icons-material';
import logo from '../assets/logo.png';

export interface HeaderProps {
  navItems?: {
    name: string;
    id: string;
  }[];
  window?: any;
}

const Header: React.FC<HeaderProps> = (props: HeaderProps) => {
  const { navItems, window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);

  const container =
    window !== undefined ? () => window().document.body : undefined;

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <Typography variant='h6' sx={{ my: 2 }}>
        Jozef Kravec
      </Typography>
      <Divider />
      {navItems && (
        <List>
          {navItems.map((item) => (
            <a href={`#${item.id}`} key={item.id}>
              <ListItem disablePadding>
                <ListItemButton sx={{ textAlign: 'center' }}>
                  <ListItemText primary={item.name} />
                </ListItemButton>
              </ListItem>
            </a>
          ))}
        </List>
      )}
    </Box>
  );

  return (
    <>
      <AppBar component='nav'>
        <Toolbar>
          <IconButton
            color='inherit'
            aria-label='open drawer'
            edge='start'
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <img
            src={logo}
            alt='logo'
            style={{ width: '24px', height: '24px', marginRight: '8px' }}
          />
          <Title variant='h5'>Jozef Kravec</Title>
          <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
            {navItems &&
              navItems.map((item) => (
                <a href={`#${item.id}`} key={item.id}>
                  <NavItem>{item.name}</NavItem>
                </a>
              ))}
          </Box>
        </Toolbar>
      </AppBar>
      {mobileOpen && (
        <Box component='nav'>
          <Drawer
            container={container}
            variant='temporary'
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: 'block', sm: 'none' },
              '& .MuiDrawer-paper': {
                boxSizing: 'border-box',
                width: 240,
              },
            }}
          >
            {drawer}
          </Drawer>
        </Box>
      )}
    </>
  );
};

export default Header;

const Title = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  flexGrow: 1,
}));

const NavItem = styled(Button)(({ theme }) => ({
  fontWeight: 600,
  color: 'white',
  fontSize: '1rem',
}));
