import Section from '../components/section';
import { Grid, Stack, Typography, styled } from '@mui/material';
import ShowerIcon from '@mui/icons-material/Shower';
import PropaneIcon from '@mui/icons-material/Propane';
import HeatPumpIcon from '@mui/icons-material/HeatPump';

import IconCard from '../components/iconCard';

const Services = () => {
  return (
    <Section id='services'>
      <Stack spacing={4}>
        <Title variant='h3'>Služby</Title>
        <Typography variant='subtitle1'>
          Špecializujeme sa na nasledúce druhy inštalatérskych a kurenárskych
          prác:
        </Typography>
        <Grid
          container
          spacing={4}
          justifyContent='center'
          style={{ marginLeft: '-32px' }}
        >
          <Grid item xs={12} lg={4} style={{ maxWidth: '500px' }}>
            <IconCard
              icon={<ShowerIcon />}
              title='Voda'
              subtitle='Montáž a servis vodovodných rozvodov vrátane sanity.'
            />
          </Grid>
          <Grid item xs={12} lg={4} style={{ maxWidth: '500px' }}>
            <IconCard
              icon={<PropaneIcon />}
              title='Plyn'
              subtitle='Preloženie a údržba plynových rozvodov.'
            />
          </Grid>
          <Grid item xs={12} lg={4} style={{ maxWidth: '500px' }}>
            <IconCard
              icon={<HeatPumpIcon />}
              title='Kúrenie'
              subtitle='Výmena a údržba všetkých druhov kotlov vrátane tepelných rozvodov.'
            />
          </Grid>
        </Grid>
      </Stack>
    </Section>
  );
};

export default Services;

const Title = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontWeight: 600,
}));
