import React from 'react';
import Section from '../components/section';
import { Typography, styled } from '@mui/material';

const Footer = () => {
  return (
    <Section bgColor='black'>
      <Title variant='subtitle2'>
        Vytvoril{' '}
        <Typography variant='subtitle1' component={'span'}>
          <a href='http://www.lukasdombrovsky.com' target='blank'>
            Lukas Dombrovsky
          </a>{' '}
        </Typography>
        | 2023
      </Title>
    </Section>
  );
};

export default Footer;

const Title = styled(Typography)(({ theme }) => ({
  color: 'white',
  fontWeight: 600,
  textAlign: 'center',

  '& a': {
    color: 'white',
  },
}));
