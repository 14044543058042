import { styled } from '@mui/material';
import React from 'react';

interface GalleryProps {
  images: string[];
}

const Gallery: React.FC<GalleryProps> = (props) => {
  const { images } = props;

  return (
    <Container>
      {images.map((image, index) => (
        <Item key={index} bgImage={image} />
      ))}
    </Container>
  );
};

export default Gallery;

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  height: '70vh',
}));

const Item = styled('div', {
  shouldForwardProp: (prop) => prop !== 'bgImage',
})<{
  bgImage: string;
}>(({ theme, bgImage }) => ({
  flex: 1,
  height: '100%',
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  transition: 'flex 0.8s ease',
  backgroundImage: `url(${bgImage})`,

  '&:hover': {
    flex: 6,
  },
}));
