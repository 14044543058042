import { Box, CssBaseline } from '@mui/material';

import './App.css';
import Welcome from './sections/welcome';
import Header from './components/header';
import Services from './sections/services';
import Reference from './sections/reference';
import Footer from './sections/footer';

const App: React.FC = () => {
  const navItems = [
    { name: 'Služby', id: 'services' },
    { name: 'Naša práca', id: 'reference' },
  ];

  return (
    <Box className='App'>
      <CssBaseline />
      <Header navItems={navItems} />
      <Welcome />
      <Services />
      <Reference />
      <Footer />
      {/* <Modal
        title='Dovolenka'
        text='Od 2.9. do 15.9. sme na dovolenke. Kontaktujte nás prosím až od 16.9. Ďakujeme za pochoponie.'
      /> */}
    </Box>
  );
};

export default App;
