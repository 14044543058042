import { Box, Stack, Typography, styled } from '@mui/material';

export interface IconCardProps {
  icon?: React.ReactNode;
  title?: string;
  subtitle?: string;
}

const IconCard = (props: IconCardProps) => {
  const { icon, title, subtitle } = props;

  return (
    <Container spacing={2} justifyContent='center' alignItems='center'>
      <IconContainer>{icon}</IconContainer>
      <Typography variant='h5'>{title}</Typography>
      <Typography variant='subtitle1'>{subtitle}</Typography>
    </Container>
  );
};

export default IconCard;

const Container = styled(Stack)(({ theme }) => ({
  height: '100%',
  padding: '48px 24px',
  backgroundColor: theme.palette.primary.main,
  boxShadow: '0px 1px 4px gray',
  borderRadius: '10px',
  textAlign: 'center',

  '& .MuiTypography-root': {
    color: 'white',
  },
}));

const IconContainer = styled(Box)(({ theme }) => ({
  padding: '8px',
  backgroundColor: theme.palette.secondary.main,
  borderRadius: '4px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  '& .MuiSvgIcon-root': {
    fill: 'white',
    fontSize: '48px',
  },
}));
