import Carousel from '../components/carousel';
import Banner from '../components/banner';

// Manual import of images
import Image1 from '../assets/carousel-1.jpg';
import Image2 from '../assets/carousel-2.jpg';
import Image3 from '../assets/carousel-3.jpg';

const images = [Image1, Image2, Image3];

const Welcome = () => {
  return (
    <Carousel images={images}>
      <Banner />
    </Carousel>
  );
};

export default Welcome;
