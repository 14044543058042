import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {
  StyledEngineProvider,
  ThemeProvider,
  createTheme,
} from '@mui/material';
import '@fontsource/montserrat';

const theme = createTheme({
  palette: {
    primary: {
      main: '#2a3c68',
    },
    secondary: {
      main: '#dd4b39',
    },
  },
  typography: {
    fontFamily: 'Montserrat',
  },
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </StyledEngineProvider>
  </React.StrictMode>
);
