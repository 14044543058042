import { Box, Stack, styled, useMediaQuery, useTheme } from '@mui/material';

export interface SectionProps {
  fullwidth?: boolean;
  bgColor?: string;
  children?: React.ReactNode;
  id?: string;
}

const Section = (props: SectionProps) => {
  const { fullwidth, bgColor, children, id } = props;

  const theme = useTheme();
  const breakpointIsXS = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Container
      direction='row'
      justifyContent='center'
      alignItems='center'
      bgColor={bgColor}
      id={id}
      breakpointIsXS={breakpointIsXS}
    >
      <Content fullwidth={fullwidth}>{children}</Content>
    </Container>
  );
};

export default Section;

const Container = styled(Stack, {
  shouldForwardProp: (prop) => prop !== 'bgColor' && prop !== 'breakpointIsXS',
})<{
  bgColor?: string;
  fullwidth?: boolean;
  breakpointIsXS?: boolean;
}>(({ theme, bgColor, breakpointIsXS }) => {
  return {
    padding: breakpointIsXS ? '100px 50px' : '100px 100px',
    backgroundColor: bgColor ? bgColor : 'white',
  };
});

const Content = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'fullwidth',
})<{
  fullwidth?: boolean;
}>(({ theme, fullwidth }) => ({
  width: '100%',
  maxWidth: fullwidth ? '100%' : '1100px',
}));
