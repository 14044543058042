import { Stack, Typography, styled } from '@mui/material';

import Section from '../components/section';
import Gallery from '../components/gallery';

// Manual import of images
import Image1 from '../assets/gallery-1.jpg';
import Image2 from '../assets/gallery-2.jpg';
import Image3 from '../assets/gallery-3.jpg';
import Image4 from '../assets/gallery-4.jpg';

const images = [Image1, Image2, Image3, Image4];

const Reference = () => {
  return (
    <Section id='reference' bgColor='#2a3c68' fullwidth>
      <Stack spacing={4}>
        <Title variant='h3'>Galéria naších prác</Title>
        <Subtitle variant='subtitle1'>
          Pozrite si fotogalériu niekoľkých našich prác.
        </Subtitle>
        <Gallery images={images} />
      </Stack>
    </Section>
  );
};

export default Reference;

const Title = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.main,
  fontWeight: 600,
}));

const Subtitle = styled(Typography)(({ theme }) => ({
  color: 'white',
  fontWeight: 500,
}));
